import React from 'react'
import SunIcon from './Icons/SunIcon'
import MoonIcon from './Icons/MoonIcon'
import avatar from '../assets/avatar.jpg'
import '../css/skeleton.css'
import '../css/normalize.css'
import '../css/components.css'

function Header() {
  const [theme, setTheme] = React.useState(() => document.body.getAttribute('data-theme') ?? 'dark')
  React.useEffect(() => {
    document.body.setAttribute('data-theme', theme)
  }, [theme])
  const handleSwitchTheme = () => {
    setTheme(isDark ? 'light' : 'dark')
  }
  const isDark = theme === 'dark'
  return (
    <div className="Header container">
      <div className="ten columns Header__inner">
        <img src={avatar} alt="avatar" />
        &nbsp;&nbsp;&nbsp;
        <h2>
          <b>Gremoria</b>
        </h2>
      </div>
      <div>
        <a href="https://cubari.moe/read/gist/cmF3L0dyZW1vcmlhLVRlYW0vZ3JlbW9yaWEtY2hhcHRlcnMvbWFzdGVyL0dyZW1vcmlhLmpzb24/" rel="noreferrer">
          Lista de capítulos
        </a>
      </div>
      <button className="switch-theme-button" onClick={handleSwitchTheme}>
        {isDark ? <SunIcon color="white" /> : <MoonIcon />}
      </button>
    </div>
  )
}

export default Header
