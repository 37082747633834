import instagram from '../assets/instagram.png'
import facebook from '../assets/facebook.png'
import twitter from '../assets/twitter.png'
import webtoon from '../assets/webtoon.png'
import artstreet from '../assets/artstreet.png'

const items = [{
    "title": "Instagram",
    "subtitle": "@gremoria_official",
    "image": instagram,
    "link": "https://instagram.com/gremoria_official" //instagram profile link 
},
{
    "title": "Twitter",
    "subtitle": "@gremoria_manga",
    "image": twitter,
    "link": "https://twitter.com/gremoria_manga"// twitter profile link 
},
{
    "title": "Facebook",
    "subtitle": "Gremoria",
    "image": facebook,
    "link": "https://www.facebook.com/profile.php?id=100069772074665" // facebook profile link
},
{
    "title": "WEBTOON",
    "subtitle": "Aquí puedes leer gremoria",
    "image": webtoon,
    "link": "https://www.webtoons.com/es/challenge/gremoria/list?title_no=652078" // webtoon link
},
{
    "title": "ARTstreet",
    "subtitle": "Aquí también puedes leer gremoria",
    "image": artstreet,
    "link": "https://medibang.com/book/l72106061137314170020866275/" // artstreet link
}]

export default items
